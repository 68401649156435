<template>
	<div class="app-body el-main">
		<div class="a-flex-rsbc">
			<bread-crumb></bread-crumb>
			<div class="a-flex-rfsc">
				<template v-if="station.companyId == company.id
				|| station.operationCompanyId == company.id
				|| station.submitterCompanyId == companyId
				|| company.id == topCompanyId">
					<el-button type="danger" icon="el-icon-close" plain>撤销站点</el-button>
					<template v-if="station.detailedState == 1">
						<el-button type="primary" class="a-ml-15" @click="applyDeviceVisible = true">申请设备</el-button>
					</template>
					<template v-if="station.detailedState == 12 || station.detailedState == 13">
						<el-button type="primary" class="a-ml-15" @click="buildInfoVisible = true">填写施工信息</el-button>
					</template>
					<template v-if="station.detailedState == 11">
						<el-button type="primary" class="a-ml-15" @click="handlerConfirmDelivery">设备确认收货</el-button>
					</template>

				</template>
				<template v-if="company.id == topCompanyId && station.detailedState == 10">
					<el-button type="primary" class="a-ml-15" @click="deliveryVisible = true">设备出库</el-button>
				</template>
				<template v-if="company.id == topCompanyId && station.detailedState == 11">
					<el-button type="primary" class="a-ml-15" @click="handlerCancelDelivery">取消出库</el-button>
				</template>


				<template v-if="company.id == topCompanyId && station.detailedState == 13">
					<el-button type="primary" class="a-ml-15" @click="checkDialogObj.dialogFormVisible = true">施工验收
					</el-button>
				</template>
			</div>

		</div>
		<div class="a-w-100 a-fs-14 a-p-24 a-br-top-4 a-bg-white">
			<div class="a-flex-rsbc">
				<div class="a-flex-rfsc">
					<span class="a-fs-16 a-fw-700 a-c-333">{{  station.name  }}</span>
					<el-tag class="a-ml-08">{{  station.statusText  }}</el-tag>
				</div>
				<div class="a-flex-rfsc a-cursor-p" @click="handlerEditStationName" v-if="$_has(7)">
					<img src="../../assets/icon/option-edit.png" class="a-wh-16" />
					<span class="a-c-blue a-ml-08">修改站点名称</span>
				</div>
			</div>
			<el-row :gutter="20" class="a-mt-32">
				<el-col :span="8">
					<div class="a-flex-rfsfs">
						<span class="a-c-999" style="width: 56px;">站点地址</span>
						<div class="a-ml-16 a-c-333">
							<span>{{  station.address  }}</span>
						</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="a-flex-rfsfs">
						<span class="a-c-999" style="width: 56px;">地图点位</span>
						<div class="a-ml-16 a-c-blue a-flex-rcc a-cursor-p" @click="handlerShowLocation">
							<span>查看</span>
							<img src="../../assets/icon/option-right-grey.png" class="jiantouimg" />
						</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="a-flex-rfsfs">
						<span class="a-c-999" style="width: 120px;">规划容量(台)</span>
						<span class="a-c-333">{{  station.capacity  }}</span>
					</div>
				</el-col>
			</el-row>

			<el-row :gutter="20" class="a-mt-16">
				<el-col :span="8">
					<div class="a-flex-rfsfs ">
						<span class="a-c-999" style="width: 56px;">所属商户</span>
						<div class="a-ml-16 a-c-blue a-flex-rcc a-cursor-p">
							<le-jumpto-detail url="/franchisee/franchisee-detail"
								:dQuery="{ 'code': station.companyId }">
								<span>{{  station.companyName  }}</span>
							</le-jumpto-detail>
							<div class="a-flex-rcc a-ml-24 " @click="handlerChangeCompany" v-if="$_has(7)">
								<img src="../../assets/icon/option-edit.png" class="a-wh-16" />
								<span class="a-ml-08">更改</span>
							</div>
						</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="a-flex-rfsfs">
						<span class="a-c-999" style="width: 56px;">运营方</span>
						<div class="a-ml-16 a-c-blue a-flex-rcc a-cursor-p">
							<le-jumpto-detail url="/franchisee/franchisee-detail"
								:dQuery="{ 'code': station.operationCompanyId }"><span>{{  station.operationCompany 
									}}</span></le-jumpto-detail>
							<!-- <span  @click="handlerGoCompanyDetail(station.operationCompanyId)">{{ station.operationCompany }}</span> -->
							<div class="a-flex-rcc a-ml-24 " @click="handlerChangeOperationCompany" v-if="$_has(7)">
								<img src="../../assets/icon/option-edit.png" class="a-wh-16" />
								<span class="a-ml-08">更改</span>
							</div>
						</div>
					</div>
				</el-col>
                <el-col :span="8">
					<div class="a-flex-rfsfs">
						<span class="a-c-999" style="width: 120px;">站点背景图片</span>
						<LeBtnPreviewImg v-if="station.backImg" :imgList="stationBackImg"></LeBtnPreviewImg>
                        <!-- <div class="a-flex-rcc a-c-blue a-ml-24 " @click="handlerChangeCompany">
                            <img src="../../assets/icon/option-edit.png" class="a-wh-16" />
                            <span class="a-ml-08">更改</span>
                        </div> -->
					</div>
				</el-col>

			</el-row>

            <el-row :gutter="20" class="a-mt-16">
				<el-col :span="8">
					<div class="a-flex-rfsfs ">
						<span class="a-c-999" style="width: 56px;">附加条件</span>
						<div class="a-ml-16 a-c-333 a-flex-rcc a-cursor-p">
							{{ station.attchInfo | initAttchInfo }}
						</div>
                        <!-- <div class="a-flex-rcc a-c-blue a-ml-24 " @click="handlerChangeCompany">
                            <img src="../../assets/icon/option-edit.png" class="a-wh-16" />
                            <span class="a-ml-08">更改</span>
                        </div> -->
					</div>
				</el-col>
                <el-col :span="8">
					<div class="a-flex-rfsfs ">
						<span class="a-c-999" style="width: 56px;">站点类型</span>
						<div class="a-ml-16 a-c-333 a-flex-rcc a-cursor-p">
							{{ station.stationType | initDic(stationTypeDic) }}
						</div>
                        <!-- <div class="a-flex-rcc a-c-blue a-ml-24 " @click="handlerChangeCompany">
                            <img src="../../assets/icon/option-edit.png" class="a-wh-16" />
                            <span class="a-ml-08">更改</span>
                        </div> -->
					</div>
				</el-col>
                <el-col :span="8">
					<div class="a-flex-rfsfs ">
						<span class="a-c-999" style="width: 120px;">所属观察</span>
						<div class="a-c-blue a-flex-rfsc a-flex-wrap">
                            <div class="observeItemAdd" @click="handlerChooseObserve" v-if="$_has(7)">添加观察</div>
                            <div class="a-flex-rfsc observeItem" v-for="(item,index) in observeList" :key="item.id">
                                <span>{{ item.name }}</span>
                                <i class="el-icon-close a-fs-14 409EFF a-ml-10" @click="handleRemoveObserve(index)"></i>
                            </div>
						</div>
                        <!-- <div class="a-flex-rcc a-ml-24 " @click="handlerChooseObserve">
                            <img src="../../assets/icon/option-edit.png" class="a-wh-16" />
                            <span class="a-ml-08 a-c-blue a-cursor-p">更改</span>
                        </div> -->
					</div>
				</el-col>
			</el-row>
            <el-row :gutter="20">
                <el-col :span="8">
					<div class="a-flex-rfsfs ">
						<span class="a-c-999" style="width: 56px;">站点标签</span>
						<div class="a-ml-16 a-c-333 a-flex-cfsfs">
							<span class="stationTag" v-for="(item,index) in station.tagList" :key="index">{{ item.name?item.name:'-' }}</span>
						</div>
					</div>
				</el-col>
                <el-col :span="8">
					<div class="a-flex-rfsfs ">
						<span class="a-c-999" style="width: 120px;">烟雾报警自动断电</span>
						<div class="a-c-333 a-flex-rcc">
							<!-- {{ station.smokeCharge == 1 ? '是' : '否' }} -->
                            <le-switch 
                                class="editDevType" 
                                style="padding: 0 !important;" 
                                label="" 
                                v-model="station.smokeCharge" 
                                @change="setSmokeCharge"
                                actext="开启" 
                                inactext="关闭"
                                :acValue="1" 
                                :inacValue="0">
                            </le-switch>
						</div>
					</div>
				</el-col>
                <el-col :span="8">
					<div class="a-flex-rfsfs ">
						<span class="a-c-999" style="width: 120px;">默认勾选安心充</span>
						<div class="a-c-333 a-flex-rcc">
                            <le-switch 
                                class="editDevType" 
                                style="padding: 0 !important;" 
                                label="" 
                                v-model="defaultSelectRestCharge" 
                                @change="restChargeChange"
                                actext="开启" 
                                inactext="关闭"
                                :acValue="1" 
                                :inacValue="0">
                            </le-switch>
						</div>
					</div>
				</el-col>
            </el-row>
            <el-row :gutter="20" class="a-mt-16">
                <el-col :span="8">
                    <div class="a-flex-rfsfs ">
						<span class="a-c-999" style="width: 120px;">是否检测门禁</span>
						<div class="a-c-333 a-flex-rcc">
                            <le-switch 
                                class="editDevType" 
                                style="padding: 0 !important;" 
                                label="" 
                                v-model="station.chargeLimit" 
                                @change="chargeLimitChange"
                                actext="开启" 
                                inactext="关闭"
                                :acValue="1" 
                                :inacValue="0">
                            </le-switch>
						</div>
					</div>
                </el-col>
                <el-col :span="8">
					<div class="a-flex-rfsfs">
						<span class="a-c-999" style="width: 56px;">消防等级</span>
						<span class="a-c-333 a-ml-16">{{ util.initDic(station.fireControlLevel, 'fireControlLevel') }}</span>
					</div>
				</el-col>
            </el-row>
		</div>

		<div class="a-flex-1">
			<el-tabs v-model="tabName" class="my-tab">
				<el-tab-pane label="建设进度" name="progress" v-if="station.status == 1">
					<station-detail-progress :stationId="stationId"></station-detail-progress>
				</el-tab-pane>
				<el-tab-pane label="统计信息" name="tjxx">
					<station-detail-stat v-if="tabName == 'tjxx'" :stationId="stationId"></station-detail-stat>
				</el-tab-pane>
				<el-tab-pane label="设备列表" name="device" v-if="$_has(12)">
					<station-detail-device :stationId="stationId"></station-detail-device>
				</el-tab-pane>
				<el-tab-pane label="站点定价" name="price" v-if="$_has(9)">
					<div class="a-br-4 a-w-100 a-bg-white a-p-24 a-h-100">
						<station-detail-price :stationId="stationId" :priceId="station.chargingPlanId">
						</station-detail-price>
					</div>
				</el-tab-pane>
				<el-tab-pane label="项目详情" name="project" v-if="$_has(25)">
					<div class="a-br-4 a-w-100 a-bg-white a-p-24 a-h-100">
						<project-confirmed :incomeId="station.incomeDistriId"></project-confirmed>
					</div>
				</el-tab-pane>
				<el-tab-pane label="高级设置" name="setting">
					<div class="a-br-4 a-w-100 a-bg-white a-p-24 a-h-100">
						<station-detail-setting :stationId="stationId"></station-detail-setting>
					</div>
				</el-tab-pane>
				<el-tab-pane label="操作历史" name="history">
					<div class="a-br-4 a-w-100 a-bg-white a-p-10 a-h-100">
						<station-detail-history :stationId="stationId"></station-detail-history>
					</div>
				</el-tab-pane>
                <el-tab-pane label="禁用支付方式" name="payType">
					<div class="a-br-4 a-w-100 a-bg-white a-p-10 a-h-100">
						<station-detail-paytype :stationId="stationId"></station-detail-paytype>
					</div>
				</el-tab-pane>
                <el-tab-pane label="站点巡检记录" name="inspection">
					<div class="a-br-4 a-w-100 a-bg-white a-p-10 a-h-100">
                        <station-detail-inspection :stationId="stationId"></station-detail-inspection>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
		<el-dialog title="高级设置" :visible.sync="changeNameVisible" class="my_dialog">
			<le-input large v-model="station.name" large></le-input>
			<span slot="footer" class="dialog-footer">
				<el-button @click="changeNameVisible = false">取 消</el-button>
				<el-button type="primary" @click="handlerEditStationNameSumbit">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="更改所属商户" :visible.sync="changeCompanyVisible" class="my_dialog">
			<le-company-all-select v-model="station.companyId" large class="changeCompany"></le-company-all-select>
			<span slot="footer" class="dialog-footer">
				<el-button @click="changeCompanyVisible = false">取 消</el-button>
				<el-button type="primary" @click="handlerChangeCompanySumbit">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="更改运营商" :visible.sync="changeOpCompanyVisible" class="my_dialog">
			<le-company-all-select v-model="station.operationCompanyId" large class="changeCompany">
			</le-company-all-select>
			<span slot="footer" class="dialog-footer">
				<el-button @click="changeOpCompanyVisible = false">取 消</el-button>
				<el-button type="primary" @click="handlerChangeOperationCompanySumbit">确 定</el-button>
			</span>
		</el-dialog>
		<le-address-map :visible.sync="dialogVisibleMap" :positionObject.sync="positionObject"></le-address-map>
		<el-dialog title="申请设备" :visible.sync="applyDeviceVisible">
			<station-detail-device-apply :stationId="stationId" @success="handlerApplyDeviceSucc"
				@cancel="applyDeviceVisible = false"></station-detail-device-apply>
		</el-dialog>
		<el-dialog title="设备出库" :visible.sync="deliveryVisible">
			<station-detail-delivery :stationId="stationId" @success="handlerDeliverySucc"
				@cancel="deliveryVisible = false"></station-detail-delivery>
		</el-dialog>
		<el-dialog title="填写施工信息" :visible.sync="buildInfoVisible">
			<station-detail-construction :stationId="stationId" @success="handlerBuildSucc"
				@cancel="buildInfoVisible = false"></station-detail-construction>
		</el-dialog>
		<le-examine-dialog :dialogObj="checkDialogObj" :visible.sync="checkDialogObj.dialogFormVisible"
			@submit="handlerCheckSucc" @refuse="handlerCheckFail"></le-examine-dialog>

        <le-observe-choose 
            :visible.sync="observeVisible"
            :observeId.sync="observe.observeId"
            :observeName.sync="observe.observeName"
            @handleChoose="handleChooseObserve"></le-observe-choose>
	</div>
</template>
<script>
import util from '../../../src/utils/util'
import leDateChangeTime from '../components/form/le-date-changeTime.vue'
import stationDetailStat from './station-detail-stat.vue'
import stationDetailDevice from './station-detail-device.vue'
import lePriceDetail from '../components/page/le-price-detail.vue'
import stationDetailPrice from './station-detail-price.vue'
import ProjectConfirmed from '../project/project-confirmed.vue'
import StationDetailSetting from './station-detail-setting.vue'
import StationDetailProgress from './station-detail-progress.vue'
import leAressMap from '../components/choose/le-address-map.vue'
import leExamineDialog from '../components/poppup/le-examine-dialog.vue'
import StationDetailDeviceApply from './station-detail-device-apply.vue'
import StationDetailDelivery from './station-detail-delivery.vue'
import StationDetailConstruction from './station-detail-construction.vue'
import StationDetailHistory from './station-detail-history.vue'
import LeBtnPreviewImg from '../components/poppup/le-btn-preview-img.vue'
import leObserveChoose from '../components/choose/le-observe-choose.vue';
import stationDetailPaytype from './station-detail-paytype.vue'
import stationDetailInspection from '@/views/station/station-detail-inspection'
import {
	mapState,
	mapActions
} from 'vuex';
let _this
export default {
	components: {
		'le-date-changeTime': leDateChangeTime,
		'station-detail-stat': stationDetailStat,
		'station-detail-device': stationDetailDevice,
		'le-price-detail': lePriceDetail,
		'station-detail-price': stationDetailPrice,
		'project-confirmed': ProjectConfirmed,
		'station-detail-setting': StationDetailSetting,
		'le-address-map': leAressMap,
		'station-detail-progress': StationDetailProgress,
		'le-examine-dialog': leExamineDialog,
		'station-detail-device-apply': StationDetailDeviceApply,
		'station-detail-delivery': StationDetailDelivery,
		'station-detail-construction': StationDetailConstruction,
		'station-detail-history':StationDetailHistory,
        LeBtnPreviewImg,
        leObserveChoose,
        stationDetailPaytype,
        stationDetailInspection
	},
	data() {
		return {
            util,
			companyId: "",
			stationId: null,
			topCompanyId: null,
			station: {
				chargingPlanId: '',
				companyId: ""
			},
			tabName: 'progress',
			changeNameVisible: false,
			changeCompanyVisible: false,
			changeOpCompanyVisible: false,
			dialogVisibleMap: false,
			positionObject: {
				'lat': '',
				'lng': '',
				'name': '',
				'address': ''
			},

			applyDeviceVisible: false,
			deliveryVisible: false,
			buildInfoVisible: false,
			checkDialogObj: {
				title: '施工验收',
				dialogFormVisible: false,
				objection: '',
				placeholder: '请输入不通过原因',
				refuse: '不通过',
				sure: '通过'
			},
            stationAttchInfoDic: [],
            stationTypeDic: [],
            observeVisible: false,
            observeList: [],
            observe:{
                observeId: '',
                observeName: ''
            },
            defaultSelectRestCharge: '', // 是否默认勾选安心充
		}
	},
	computed: {
		...mapState({
			company: state => state.company.company,
		}),
        stationBackImg () {
            if(this.station.backImg && this.station.backImg.length){
                return this.station.backImg.filter(item=>{
                    return item
                })
            }else{
                return []
            }
        }
	},

	mounted() {
        _this = this
		this.stationId = parseInt(this.$route.query.stationId)
		this.getStationDetail()
        this.getStationObserve()
        this.getDefaultSelectRestChargeConfig()
        this.$getDic('stationAttchInfo').then(res=>{ this.stationAttchInfoDic = res; })
        this.$getDic('stationType').then(res=>{ this.stationTypeDic = res; })
	},

    filters:{
        initAttchInfo (val) {
            let arr = []
            if(val && val.length && _this.stationAttchInfoDic && _this.stationAttchInfoDic.length){
                val = JSON.parse(val)
                arr = _this.stationAttchInfoDic.filter(res=>{
                    if(val.indexOf(res.value) != -1){
                        return res
                    }
                })
            }
            if(arr.length){
                arr = arr.map(item=>{
                    return item.text
                })
            }else{
                arr = '-'
            }
            
            return arr.toString().replaceAll(',','，')
        }
    },

	methods: {
		/**
		 * 获取站点详细信息
		 */
		getStationDetail() {
			this.$Axios._get({
				url: this.$Config.apiUrl.getStationDetail,
				params: {
					stationId: this.stationId
				}
			}).then(({
				data
			}) => {
				this.station = data.station
				this.positionObject = {
					'lat': this.station.lat,
					'lng': this.station.lng,
					'name': this.station.name,
					'address': this.station.address
				}
				this.getTopCompanyId(this.station.companyId)
                this.station.backImg = this.station.backImg?JSON.parse(this.station.backImg):[]
				if(this.station.status != 1){
					this.tabName = 'tjxx'
				}
			})
		},

		/**
		 * 获取顶级
		 */
		getTopCompanyId(stationOwnerCompanyId) {
			this.$Axios._get({
				url: this.$Config.apiUrl.getTopCompanyIdWithCompanyId,
                showLoading: false,
				params: {
					companyId: stationOwnerCompanyId,
				}
			}).then(({
				data
			}) => {
				this.topCompanyId = data
			})
		},
		/**
		 * 编辑站点名称
		 */
		handlerEditStationName() {
			this.changeNameVisible = true;
		},

		handlerEditStationNameSumbit() {
			this.$Axios._get({
				url: this.$Config.apiUrl.changeStationName,
				params: {
					stationId: this.stationId,
					name: this.station.name
				}
			}).then(({
				data
			}) => {
				this.changeNameVisible = false;
				this.$message.success("修改站点名称成功")
				this.getStationDetail()
			})
		},
		/**
		 * 显示站点地址
		 */
		handlerShowLocation() {
			this.$set(this, 'dialogVisibleMap', true)

		},
		/**
		 * 更改站点所属商户
		 */
		handlerChangeCompany() {
			this.changeCompanyVisible = true;
		},

		handlerChangeCompanySumbit() {
			this.$Axios._get({
				url: this.$Config.apiUrl.changeStationCompanyId,
				params: {
					stationId: this.stationId,
					companyId: this.station.companyId
				}
			}).then(({
				data
			}) => {
				this.changeCompanyVisible = false;
				this.$message.success("修改站点所属商户成功")
				this.getStationDetail()
			})
		},
		/**
		 * 更改站点运营方
		 */
		handlerChangeOperationCompany() {
			this.changeOpCompanyVisible = true
		},
		handlerChangeOperationCompanySumbit() {
			this.$Axios._get({
				url: this.$Config.apiUrl.changeStationOpCompanyId,
				params: {
					stationId: this.stationId,
					opCompanyId: this.station.operationCompanyId
				}
			}).then(({
				datastation
			}) => {
				this.changeOpCompanyVisible = false;
				this.$message.success("修改站点运营商户成功")
				this.getStationDetail()
			})
		},

		/**
		 * 站点验收通过
		 */
		handlerCheckSucc(msg) {

			this.$Axios._post({
				url: this.$Config.apiUrl.authBuildInfo,
				params: {
					stationId: this.stationId,
					authResult: true
				}
			}).then(({
				data
			}) => {
				this.$message.success("验收通过成功")
				this.getStationDetail()
				this.$eventBus.$emit('station_fresh_checkInfo')
			})
		},
		/**
		 * 站点验收通过
		 */
		handlerCheckFail(msg) {

			this.$Axios._post({
				url: this.$Config.apiUrl.authBuildInfo,
				params: {
					stationId: this.stationId,
					authResult: false,
					authMsg: msg
				}
			}).then(({
				data
			}) => {
				this.$message.success("验收不通过")
				this.getStationDetail()
				this.$eventBus.$emit('station_fresh_checkInfo')
			})
		},

		/**
		 * 申请设备成功回调
		 */
		handlerApplyDeviceSucc() {
			this.applyDeviceVisible = false
			this.getStationDetail()
		},
		/**
		 * 设备出库成功回调
		 */
		handlerDeliverySucc() {
			this.deliveryVisible = false
			this.getStationDetail()
		},
		/**
		 * 填写施工信息成功回调
		 */
		handlerBuildSucc() {
			this.buildInfoVisible = false
			this.getStationDetail()
		},
		/**
		 * 确认收货
		 */
		handlerConfirmDelivery() {
			let that = this;
			this.$confirm('是否确认收货?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				that.$Axios._get({
					url: that.$Config.apiUrl.confirmDeviceReceived,
					params: {
						stationId: that.stationId,
					}
				}).then(({
					data
				}) => {
					that.$message.success("操作成功")
					that.getStationDetail()
				})
			});
		},
		/**
		 * 取消出库
		 */
		handlerCancelDelivery() {
			let that = this;
			this.$confirm('是否撤销出库?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				that.$Axios._get({
					url: that.$Config.apiUrl.cancelStationDeviceShip,
					params: {
						stationId: that.stationId,
					}
				}).then(({
					data
				}) => {
					that.$message.success("操作成功")
					that.getStationDetail()
				})
			});
		},

        // 选择观察
        handlerChooseObserve () {
            this.observeVisible = true
        },
        // 选择观察回调
        handleChooseObserve () {
            let that = this;
			that.$Axios._post({
                url: that.$Config.apiUrl.addObserveStation,
                params: {
                    observeId: that.observe.observeId,
                    stationId: that.stationId,
                }
            }).then(({
                data
            }) => {
                that.$message.success("操作成功")
                that.getStationObserve()
            })
        },
        setSmokeCharge (val) {
            this.$Axios._post({
                url: this.$Config.apiUrl.updateSmokeCharge,
                showLoading: false,
                params: {
                    smokeCharge: val,
                    stationId: this.stationId,
                }
            }).then(({
                data
            }) => {
                this.$message.success("操作成功")
                this.getStationDetail()
            })
        },
        getStationObserve () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getObserveStationInfo,
                params: this.stationId,
            }).then(({
                data
            }) => {
                this.observeList = data
            })
            
        },
        handleRemoveObserve (index) {
            let that = this;
            let observeId = this.observeList[index].id
			this.$confirm('是否确定删除观察?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				that.$Axios._post({
					url: that.$Config.apiUrl.delObserveStation,
					params: {
                        observeId: observeId,
						stationId: that.stationId,
					}
				}).then(({
					data
				}) => {
					that.$message.success("操作成功")
					that.getStationObserve()
				})
			});
        },
        // 查询站点是否默认勾选安心充
        getDefaultSelectRestChargeConfig () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getAnxinTag,
                params: {
                    scopeId: this.stationId,
                    scopeType: 3,//1--商户，2--项目，3--站点
                }
            }).then(({
                data
            }) => {
                if(data) {
                    this.defaultSelectRestCharge = data.tag
                }else {
                    this.defaultSelectRestCharge = 1
                }
            })
        },
        restChargeChange (e) {
            this.$Axios._post({
                url: this.$Config.apiUrl.setAnxinTag,
                params: {
                    scopeId: this.stationId,
                    scopeType: 3,//1--商户，2--项目，3--站点
                    tag: e,// 1--勾选,0--不勾选
                }
            }).then(({
                data
            }) => {
                this.$message.success("操作成功")
            })
        },
        chargeLimitChange (e) {
            this.$Axios._post({
                url: this.$Config.apiUrl.updateChargeLimit,
                params: {
                    stationId: this.stationId,
                    chargeLimit: e,// 1--勾选,0--不勾选
                }
            }).then(({
                data
            }) => {
                this.$message.success("操作成功")
            })
        }
	}
}
</script>
<style lang="scss" scoped>
.my-tab {
	height: 100%;
	display: flex;
	flex-direction: column;

	/deep/ .el-tabs__nav-wrap {
		background-color: #FFF;
		padding: 0 24px;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;

		&::after {
			background-color: white !important;
		}
	}

	/deep/ .el-tabs__content {
		flex: 1;

		.el-tab-pane {
			height: 100%;
		}
	}
}

.my_dialog {
	/deep/ .el-dialog {
		width: 454px;
	}

	/deep/ .el-dialog__header {
		border-bottom: 1px solid #EBF0F5;
	}

	/deep/ .el-dialog__footer {
		border-top: 1px solid #EBF0F5;
	}
}

.jiantouimg {
	width: 6px;
	height: 10px;
	margin-left: 8px;
}

.changeCompany /deep/ .el-select {
	margin-left: 0;

	.el-input__inner {
		width: 100%;
	}
}
.observeItemAdd{
    padding: 1px 8px;
    background: #409EFF;
    color: #ffffff;
    border-radius: 4px;
    font-size: 12px;
    margin-right: 12px;
    margin-bottom: 10px;
    cursor: pointer;
}
.observeItem{
    padding: 1px 8px;
    background: #d9ebff;
    color: #409EFF;
    border-radius: 4px;
    font-size: 12px;
    margin-right: 12px;
    margin-bottom: 10px;
    cursor: pointer;
}
.stationTag{
    padding: 0px 6px;
    background: #f4f4f5;
    color: #909399;
    border: 1px solid #e9e9eb;
    border-radius: 3px;
    margin-right: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    word-break: keep-all;
}
</style>
